
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    SUPPLIER_LIST
} from '../actionTypes';

import {
    getSupplierList,
    getSupplierListSuccess
} from '../supplier/actions';

import axios from "axios";
import {apiUrl} from "../../config/AppConfig";

const url = apiUrl;

const getSuppliersRequest = async () => {
    return axios.get(url+'/api/supplier', {
        cache: true,
        auth: {
            username: localStorage.getItem('username'),
            password: localStorage.getItem('password')
        }
    })
};

function* checkSuppliersList() {
    try {
        const res = yield call(getSuppliersRequest);

        yield put(getSupplierListSuccess(res.data));

    } catch (error) {
        // yield put(accessUserFails());
    }
}

export function* watchSupplierList() {
    yield takeEvery(SUPPLIER_LIST, checkSuppliersList);
}

export default function* rootSaga() {
    yield all([
        fork(watchSupplierList)
    ]);
}