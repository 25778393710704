import React from "react";
import { GridDetailRow} from '@progress/kendo-react-grid';

class SupplierDetail extends GridDetailRow {
    render() {
        const dataItem = this.props.dataItem;
        return (
            <section>
                <p><strong>توضیحات:</strong> {dataItem.description}</p>
            </section>
        );
    }
}

export default SupplierDetail;