import React, { Component, Fragment } from "react";
import { Renderers } from "./renderers";
import axios from "axios";
import {connect} from "react-redux";
import { getProductsList, getCategoryList, setProductsGrid } from '../../data/priceList/actions';
import { getSupplierList } from '../../data/supplier/actions';
import CategorySelect from "./categorySelect";
import {apiUrl} from "../../config/AppConfig";
// import statFilterCell from './statFilter';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {Keys} from '../../components/keys';
import {Button} from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';

class Prices extends Component{
    constructor(props) {
        super(props);

        this.state = {
            gridDataState: {},
            inEditField: null,
            editedField: null,
            loading: false,
            updateLoading: false,
            updateErr: false,
            catDisplay: true,
            searchMsg: false,
            modalVisible: false,
            modalProduct: null,
            selectedSupplier: false,
            supplierData: [],
            supplier: false,
            totalSearchValue: '',
        };
    }

    componentDidMount() {
        this.props.getProductsList();
        this.props.getCategoryList();
        this.props.getSupplierList();
    }

    enterEdit = (dataItem, field) => {
        const data = this.props.priceApp.gridProducts.map(item => ({
                ...item,
                inEdit: item.id_product === dataItem.id_product && item.id_product_attribute === dataItem.id_product_attribute ? field : undefined
            })
        );

        this.props.setProductsGrid(data);
        this.setState({inEditField: field});
    };

    exitEdit = () => {
        const data = this.props.priceApp.gridProducts.map(item => (
            { ...item, inEdit: undefined }
        ));

        this.props.setProductsGrid(data);
        this.setState({ inEditField: undefined });
    };

    remoteEdit = () => {
        let editField = this.state.editedField;
        if(editField) {
            this.setState({updateLoading: true});
            // Remote Call Update
            axios.put(apiUrl + '/api/products/' + editField.id, {
                id: editField.id,
                attribute_id: editField.attribute_id,
                [editField.field]: editField.value
            }, {
                auth: {
                    username: 'apiuser',
                    password: 'apisecret'
                }
            }).then(res => {
                this.setState({updateLoading: false, updateErr: false});
            }).catch(err => {
                this.setState({updateLoading: false, updateErr: true});
            });
        }
    };

  itemChange = (event) => {
      const inEditID = event.dataItem.id_product;
      this.setState({editedField: {
          id: inEditID,
          attribute_id: event.dataItem.id_product_attribute,
          field: event.field,
          value: event.value
      }});
      const data = this.props.priceApp.gridProducts.map(item =>
          item.id_product === inEditID && item.id_product_attribute === event.dataItem.id_product_attribute ? {...item, [event.field]: event.value} : item
      );
      this.props.setProductsGrid(data);
  };

  toggleSearch = () => {
      this.setState({ catDisplay: !this.state.catDisplay });
      this.setState({supplierData: this.props.supplierApp.allSuppliers});
  };

  _export;
  export = () => {
    this._export.save();
  };

    // @todo: make up and down keys practical.
    handleKeyDown = e => {
        if (e.keyCode === Keys.esc) {
            this.exitEdit();
        }

        if (e.target.nodeName === "INPUT") {
            if (e.keyCode === Keys.enter) {
                this.remoteEdit();
                this.exitEdit();
            }
        }
    };

    toggleSupplierModal = (product = null) => {
        this.setState({
            modalVisible: !this.state.modalVisible,
            modalProduct: product,
            selectedSupplier: this.props.supplierApp.allSuppliers[0].id
        });

        this.setState({supplierData: this.props.supplierApp.allSuppliers});
    };

    updateSupplierAttach = (res) => {
        const data = this.props.priceApp.gridProducts.map(item => (
                item.id_product === res.id_product && item.id_product_attribute === res.id_product_attribute ? res : item
            )
        );

        this.props.setProductsGrid(data);
        this.setState({modalProduct: res});
    };

    detachSupplier = (supplierTitle) => {
        const url =apiUrl+"/api/supplierable/";

        axios.delete(url + this.state.modalProduct.id_product + '/'+ this.state.modalProduct.id_product_attribute + '/' + supplierTitle, {
            auth: {
                username: 'apiuser',
                password: 'apisecret'
            }
        }).then(res => {
            this.updateSupplierAttach(res.data);
        }).catch(err => {
            // this.setState({loading: false});
            // this.setState({error: err});
        });
    };

    addSupplier = () => {
        const url =apiUrl+"/api/supplierable";

        let item = {
            id_product: this.state.modalProduct.id_product,
            id_attribute: this.state.modalProduct.id_product_attribute,
            supplier_id: this.state.selectedSupplier
        };

        if(this.state.selectedSupplier) {
            axios.post(url, item, {
                auth: {
                    username: 'apiuser',
                    password: 'apisecret'
                }
            }).then(res => {
                this.updateSupplierAttach(res.data);
            }).catch(err => {
                // this.setState({loading: false});
                // this.setState({error: err});
            });
        } else {
            return false;
        }
    };

    searchSupplier = (needle) => {
        this.resetTotalSearch();
        this.setState({supplier: needle});
        if(needle) {
            const data = this.props.priceApp.allProducts.filter(item => item.suppliers ? item.suppliers.includes(needle.supplier_title) : false);
            this.props.setProductsGrid(data);
        }
    };

    filterChange = (event) => {
        this.setState({
            supplierData: this.filterData(event.filter)
        });
    };

    filterData(filter) {
        const data = this.props.supplierApp.allSuppliers.slice();
        return filterBy(data, filter);
    }

    resetSupplierSearch = () => {
        this.setState({ supplier: false });
    }

    resetTotalSearch = () => {
        this.setState({ totalSearchValue: '' });
    }

    render() {
      const renderers = new Renderers(this.enterEdit, this.exitEdit, this.remoteEdit, 'inEdit');

      const {
          allProducts,
          gridProducts,
          loading,
          ploading
      } = this.props.priceApp;

      const colWidthsCook = localStorage.getItem('NGridColumnSizes') ? JSON.parse(localStorage.getItem('NGridColumnSizes')) : {};

      return (
          <div className="App">
              { loading || ploading ?
                    <Fragment>
                        در حال بارگذاری محصولات از فروشگاه...
                        <div className="loading"></div>
                    </Fragment>
                  :
                  <Fragment>
                      {this.state.updateLoading ?
                          <div className='updatePrice'> در حال بروزرسانی فروشگاه ... </div> : ""
                      }
                      {this.state.updateErr ?
                          <div className='updateError'> خطا در بروزرسانی محصول </div> : ""
                      }

                      <div className='catContainer'>
                            <CategorySelect
                                resetSupplier = {this.resetSupplierSearch}
                                resetTotal = {this.resetTotalSearch}
                            />
                            <div>
                                <i className='lni lni-search lni-lg' onClick={ this.toggleSearch }/>
                                <input className='k-textbox' placeholder='عنوان محصول...' value={this.state.totalSearchValue} onChange={(e) => {
                                    let keyWord = e.target.value;
                                    this.setState({totalSearchValue: e.target.value});
                                    this.resetSupplierSearch();
                                    // cause of performance matters
                                    if(keyWord.length > 2) {
                                        const data = allProducts.filter(item => item.legend.toLowerCase().includes(keyWord.toLowerCase()));
                                        this.props.setProductsGrid(data);
                                        this.setState({searchMsg: false});
                                    } else {
                                        keyWord.length > 0 
                                        ? 
                                            this.setState({searchMsg: 'ورود بیش از 2 حرف الزامی است...'})
                                        :
                                            this.setState({searchMsg: false})
                                    }

                                }}/>
                                <ComboBox
                                    placeholder={'تامین کننده...'}
                                    style={{marginRight: 20}}
                                    data={this.state.supplierData}
                                    textField="supplier_title"
                                    dataItemKey="id"
                                    filterable={true}
                                    onFilterChange={this.filterChange}
                                    onChange={(e) => this.searchSupplier(e.target.value)}
                                    value={this.state.supplier}
                                ></ComboBox>
                                { this.state.searchMsg }
                            </div>                            
                      </div>

                      <div dir="rtl" className="k-rtl" onKeyDown={this.handleKeyDown}>
                          <ExcelExport
                              data={allProducts}
                              ref={exporter => this._export = exporter}
                          >
                          <Grid
                              data={process(gridProducts, this.state.gridDataState)}
                              editField="inEdit"
                              // pageable={true}
                              sortable={true}
                              filterable={true}
                              {...this.state.gridDataState}
                              onDataStateChange={(e) => {
                                  this.setState({ gridDataState: e.data })
                              }}
                              onItemChange={this.itemChange}
                              resizable={true}
                              cellRender={renderers.cellRender}
                              rowRender={renderers.rowRender}
                              onColumnResize={event => {
                                  if(event.end) {
                                      let vals = {};
                                      if(localStorage.getItem('NGridColumnSizes')) {
                                          vals = JSON.parse(localStorage.getItem('NGridColumnSizes'));
                                      }
                                      let index = parseInt(event.index);
                                      let nvals = {...vals, [index]:event.newWidth };
                                      localStorage.setItem('NGridColumnSizes', JSON.stringify(nvals));
                                  }
                              }}
                              style={{height: '72vh', overflow: 'scroll', fontSize: '0.8rem'}}
                          >
                              <GridColumn field="id_product" width={colWidthsCook["0"] || 130} title="کد" editable={false}
                                cell={(props) => {
                                    return <td className="codeLink"><a target="_blank" href={"https://asaksystem.ir/index.php?id_product=" + props.dataItem.id_product + "&controller=product"}> { props.dataItem.id_product } </a></td>
                                }}
                              />
                              <GridColumn field="legend" title="نام محصول" width={colWidthsCook["1"] || 330} className="product-name" editable={false} />
                              <GridColumn field="combinationName" title="ترکیب" width={colWidthsCook["2"] || 230} editable={false}/>
                              <GridColumn field="price" width={colWidthsCook["3"] || 140} title="قیمت فروش" editor="numeric" format="{0:n}" className="sell-price" />
                              <GridColumn field="wholesale_price" width={colWidthsCook["4"] || 140} title="قیمت خرید" editor="numeric" format="{0:n}" className="wholesale-price"/>
                              <GridColumn field="quantity" width={colWidthsCook["5"] || 140} editor="numeric" title="موجودی" format="{0:n}" className="quantity"/>
                              <GridColumn field="description" width={colWidthsCook["6"] || 140} title="توضیحات" />
                              <GridColumn field="suppliers" width={colWidthsCook["7"] || 150} title="تامین کنندگان" editable={false} />
                              <GridColumn title="عملیات" filterable={false} width={60} cell={(props) => { return (
                                  <td>
                                      <Button className="btn btn-info btn-sm m-1" onClick={ () => this.toggleSupplierModal(props.dataItem) }>
                                          <i className='lni lni-plus lni-16' />
                                      </Button>
                                  </td>
                                  )}} />
                          </Grid>
                          </ExcelExport>
                      </div>
                      <div>
                          <button
                              title="Export Excel"
                              className="k-button k-primary"
                              onClick={this.export}
                              style={{float: "right", marginTop:'10px'}}
                          >
                              خروجی اکسل
                          </button>
                      </div>
                      {this.state.modalVisible && <Dialog title={"افزودن تامین کننده"} onClose={() => this.toggleSupplierModal()}>
                          <h3> { this.state.modalProduct.legend } </h3>
                          <p style={{ margin: "25px", textAlign: "right" }}>
                              {/*<select className="customSelect" onChange={(e) => this.setState({selectedSupplier: e.target.value})}>*/}
                              {/*    { allSuppliers.map(item => <option value={item.id}>{item.supplier_title}</option> ) }*/}
                              {/*</select>*/}
                              <ComboBox
                                  data={this.state.supplierData}
                                  textField="supplier_title"
                                  dataItemKey="id"
                                  filterable={true}
                                  onFilterChange={this.filterChange}
                                  onChange={(e) => this.setState({selectedSupplier: e.target.value})}
                              ></ComboBox>
                              <button className="k-button" onClick={ this.addSupplier } style={{ marginRight: 20 }}>
                                  <i className="lni lni-plus"></i>
                              </button>
                          </p>
                          <ul>
                              {this.state.modalProduct.suppliers ?
                                  this.state.modalProduct.suppliers.split(",").map((item, index) =>
                                  <li key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                      <div>{item}</div>
                                      <button className="k-button" onClick={ () => this.detachSupplier(item) }>
                                          <i className="lni lni-minus"></i>
                                      </button>
                                  </li>)
                                  : ""
                              }
                          </ul>
                          <DialogActionsBar>
                              <button className="k-button" onClick={() => this.toggleSupplierModal()}> بستن </button>
                          </DialogActionsBar>
                      </Dialog>}
                  </Fragment>
              }
          </div>
      );
  }
}

const mapStateToProps = ({ priceApp, supplierApp }) => {
    return {
        priceApp,
        supplierApp
    };
};

export default connect(
    mapStateToProps,
    {
        getProductsList,
        getCategoryList,
        setProductsGrid,
        getSupplierList
    }
)(Prices);