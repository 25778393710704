import {
    AUTH_GET_CREDENTIAL,
    AUTH_GET_CREDENTIAL_SUCCESS,
    AUTH_GET_CREDENTIAL_ERROR,
    AUTH_LOGOUT
} from '../actionTypes';

const INIT_STATE = {
    allowed: true,
    error: false,
    loading: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case AUTH_GET_CREDENTIAL:
            return { ...state, allowed:true, loading: true, error: false };

        case AUTH_GET_CREDENTIAL_SUCCESS:
            return { ...state, allowed:true, loading: false, error: false };

        case AUTH_GET_CREDENTIAL_ERROR:
            return { ...state, allowed:false, loading: false, error: action.payload };

        case AUTH_LOGOUT:
            localStorage.removeItem('password');
            localStorage.removeItem('username');
            return { ...state, loading: false, error: false, allowed: false };

        default: return { ...state };
    }
}
