import React from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'
import store from './data/store'
import './assets/index.css';
import Routes from './routes'

const rootElement = document.getElementById('root');
ReactDOM.render(
    <Provider store={store}>
        <Routes />
    </Provider>
    ,
    rootElement
);