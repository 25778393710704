import {
    AUTH_GET_CREDENTIAL,
    AUTH_GET_CREDENTIAL_SUCCESS,
    AUTH_GET_CREDENTIAL_ERROR,
    AUTH_LOGOUT
} from '../actionTypes';

export const getUserCredential = () => ({
    type: AUTH_GET_CREDENTIAL
});

export const getUserCredentialSuccess = (msg) => ({
    type: AUTH_GET_CREDENTIAL_SUCCESS,
    payload: msg
});

export const getUserCredentialError = (msg) => ({
    type: AUTH_GET_CREDENTIAL_ERROR,
    payload: msg
});

export const userLogout = () => ({
    type: AUTH_LOGOUT
});
