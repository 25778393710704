export const PRODUCT_GET_LIST = 'PRODUCT_GET_LIST';
export const PRODUCT_GET_LIST_SUCCESS = 'PRODUCT_GET_LIST_SUCCESS';
export const PRODUCT_GET_GRID = 'PRODUCT_GET_GRID';
export const PRODUCT_SET_GRID = 'PRODUCT_SET_GRID';
export const PRODUCT_SET_SOCKET_UPDATE = 'PRODUCT_SET_SOCKET_UPDATE';

export const CATEGORY_GET_LIST = 'CATEGORY_GET_LIST';
export const CATEGORY_GET_LIST_SUCCESS = 'CATEGORY_GET_LIST_SUCCESS';

export const AUTH_GET_CREDENTIAL = 'AUTH_GET_CREDENTIAL';
export const AUTH_GET_CREDENTIAL_SUCCESS = 'AUTH_GET_CREDENTIAL_SUCCESS';
export const AUTH_GET_CREDENTIAL_ERROR = 'AUTH_GET_CREDENTIAL_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SUPPLIER_LIST = 'SUPPLIER_LIST';
export const SUPPLIER_LIST_SUCCESS = 'SUPPLIER_LIST_SUCCESS';