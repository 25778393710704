import React, {Component} from 'react';
import {connect} from "react-redux";
import { Label } from '@progress/kendo-react-labels';
import { Input } from "@progress/kendo-react-inputs";
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Button } from '@progress/kendo-react-buttons';
import {requiredValidator} from '../../components/validator';
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import {getProductsList} from "../../data/priceList/actions";

class AddInvoice extends Component{
    constructor(props) {
        super(props);

        this.state = {
            customer: '',
            invoiceDate: '',
            autoCompleteValue: [{key: 0, value: '', price: 0, id: 0, id_attribute: 0}],
            autoCompleteData: [],
            productNumbers: [{key:0, value:''}],
            productDescriptions: [{key:0, value:''}],
        };
    }

    componentDidMount() {
        this.props.getProductsList();
    }

    handleSubmit = (dataItem) => {
        // this.props.getUserCredential();
    };

    autoCompleteChange = (event, key) => {
        let data = this.props.priceApp.allProducts;
        const value = event.target.value;
        let selected = {value: value, id:0, price:0, id_attribute:0};

        if(event.target.props.data[0]) {
            let selData = event.target.props.data[0];
            selected = {value: value, id:selData.id_product, price:selData.price, id_attribute:selData.id_product_attribute};
        }

        let prds = this.state.autoCompleteValue.map(item => item.key === key ? {...item, ...selected} : item);

        this.setState({
            autoCompleteValue: prds,
        });

        if(value.length > 3) {
            this.setState({
                autoCompleteData: data.filter(item => item.legend.includes(value))
            });
        }
    };

    getProductNumberValue = (i) => {
        let val = this.state.productNumbers.filter(item => item.key === i);
        return val[0] ? val[0].value : '';
    };

    getProductDescriptionValue = (i) => {
        let val = this.state.productDescriptions.filter(item => item.key === i);
        return val[0] ? val[0].value : '';
    };

    getProductValue = (i) => {
        let val = this.state.autoCompleteValue.filter(item => item.key === i);
        return val[0] ? val[0].value : '';
    };

    addRow = (e) => {
        let data = [...this.state.productNumbers, {key:this.state.productNumbers.length, value: ''}];
        let adata = [...this.state.autoCompleteValue, {key:this.state.autoCompleteValue.length, value: '', price: 0, id: 0}];
        this.setState({productNumbers: data, autoCompleteValue: adata});
    };

    removeRow = (key) => {
        if(this.state.productNumbers.length > 1) {
            let data = this.state.productNumbers.filter(item => item.key !== key);
            let adata = this.state.autoCompleteValue.filter(item => item.key !== key);
            this.setState({productNumbers: data, autoCompleteValue: adata});
        }
    };

    render() {
        const { allProducts, loading } = this.props.priceApp;

        return (
            <div className="k-rtl App" style={{ marginTop: '10px'}}>
                <h3 style={{paddingRight: 40}}>افزودن فاکتور</h3>
                <div className="k-flex-row formRow">
                    <Label editorId='customer' className='formLabel'>
                        مشتری:
                    </Label>
                    <Input
                        id='customer'
                        value={this.state.customer}
                        onChange={(e) => this.setState({customer: e.value})}
                    />
                </div>
                <div className="k-flex-row formRow">
                    <Label editorId='invoiceDate' className='formLabel'>
                        تاریخ:
                    </Label>
                    <DatePicker
                        id='invoiceDate'
                        // format="y/M/d"
                    />
                </div>
                <div className="k-flex-row formRow k-d-flex k-align-items-center">
                    <Label editorId='invoiceDate' className='formLabel'>
                        محصولات:
                    </Label>
                    { loading ? <div className="loading"></div> :
                    <table className="addInvoiceTable">
                        <thead>
                            <tr>
                                <th>محصول</th>
                                <th>تعداد</th>
                                <th>بهای واحد</th>
                                <th>مبلغ کل</th>
                                <th>شرح کالا</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.productNumbers.map(item =>
                            <tr key={item.key}>
                                <td>
                                    <AutoComplete
                                        data={this.state.autoCompleteData}
                                        value={this.getProductValue(item.key)}
                                        onChange={(e) => {this.autoCompleteChange(e, item.key)}}
                                        itemRender = { (li, itemProps) => {
                                            // console.log(itemProps);
                                            const index = itemProps.dataItem.legend + '|' + itemProps.dataItem.combinationName;
                                            const itemChildren = <span>{index}</span>;

                                            itemProps.dataItem.kk = itemProps.dataItem.legend + '|' + itemProps.dataItem.combinationName;

                                            return React.cloneElement(li, li.props, itemChildren);
                                        }}
                                        textField={'legend'}
                                        placeholder="نام محصول"
                                        style={{width: '300px'}}
                                    />
                                </td>
                                <td>
                                    <Input value={this.getProductNumberValue(item.key)}
                                    onChange={(e) => {
                                        let pnums = this.state.productNumbers.map(itm => itm.key === item.key ? {...itm, value: e.value} : itm);
                                        this.setState({productNumbers: pnums});
                                    }}
                                    style={{width: '50px'}}
                                    />
                                </td>
                                <td style={{textAlign: 'center', width: 100}}>
                                    { this.state.autoCompleteValue.filter(itm => itm.key === item.key)[0].price }
                                </td>
                                <td style={{textAlign: 'center', width: 100}}>
                                    { this.state.autoCompleteValue.filter(itm => itm.key === item.key)[0].price *
                                    this.state.productNumbers.filter(itm => itm.key === item.key)[0].value}
                                </td>
                                <td>
                                    <Input value={this.getProductDescriptionValue(item.key)}
                                           onChange={(e) => {
                                               let pnums = this.state.productDescriptions.map(itm => itm.key === item.key ? {...itm, value: e.value} : itm);
                                               this.setState({productDescriptions: pnums});
                                           }}
                                    />
                                </td>
                                <td><Button onClick={(e) => this.removeRow(item.key)}><i className='lni lni-minus lni-16' /></Button></td>
                            </tr>)
                        }
                        <tr>
                            <td><Button onClick={this.addRow}><i className='lni lni-plus lni-16' /></Button></td>
                        </tr>
                        </tbody>
                    </table> }
                </div>
                <div className="k-form-buttons" style={{paddingRight: 40}}>
                    <Button
                        primary={true}
                        icon={'save'}
                    >
                        ثبت
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ priceApp }) => {
    return {
        priceApp
    };
};

export default connect(
    mapStateToProps,
    {
        getProductsList
    }
)(AddInvoice);