
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    PRODUCT_GET_LIST,
    CATEGORY_GET_LIST
} from '../actionTypes';

import {
    getCategoryListSuccess,
    getProductsList,
    getProductsListSuccess
} from '../priceList/actions';
import axios from "axios";
import {apiUrl} from "../../config/AppConfig";

const url = apiUrl;

const getProductsRequest = async () => {
    return axios.get(url+'/api/products', {
        cache: true,
        auth: {
            username: localStorage.getItem('username'),
            password: localStorage.getItem('password')
        }
    })
};

const getCategoryRequest = async () => {
    return axios.get(url+'/api/category', {
        cache: true,
        auth: {
            username: localStorage.getItem('username'),
            password: localStorage.getItem('password')
        }
    })
};

function* checkProductList() {
    try {
        const res = yield call(getProductsRequest);

        yield put(getProductsListSuccess(res.data));

    } catch (error) {
        // yield put(accessUserFails());
    }
}

function* checkCategoryList() {
    try {
        const res = yield call(getCategoryRequest);

        yield put(getCategoryListSuccess(res.data));

    } catch (error) {
        // yield put(accessUserFails());
    }
}

export function* watchProductList() {
    yield takeEvery(PRODUCT_GET_LIST, checkProductList);
}
export function* watchCategoryList() {
    yield takeEvery(CATEGORY_GET_LIST, checkCategoryList);
}

export default function* rootSaga() {
    yield all([
        fork(watchProductList),
        fork(watchCategoryList)
    ]);
}