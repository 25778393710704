import React, { Component, Fragment } from "react";
import { loadMessages, LocalizationProvider } from '@progress/kendo-react-intl';
import { Grid, GridColumn} from '@progress/kendo-react-grid';
import messages from '../../lang/kendo-fa-IR';
import { HistoryLoader } from './historyLoader';

class PricesHistory extends Component{
    constructor(props) {
        super(props);

        this.state = {
            history: {data: []},
            loading: false,
            filter: {
                logic: "and",
                filters: []
            },
            page: 0
        };

        const language = 'fa';
        loadMessages(messages, language);
    }

    dataRecieved = (history) => {
        this.setState({
            ...this.state,
            history: history
        });
    }

  render() {
      return (
          <div className="App">
              { this.state.loading ?
                    <Fragment>
                        در حال بارگذاری تاریخچه...
                        <div className="loading"></div>
                    </Fragment>
                  :
                  <div className="">
                    <h4> تاریخچه تغییرات </h4>  
                    <LocalizationProvider language="fa">
                      <div dir="rtl" className="k-rtl my-3" style={{ height: '75vh', overflow: 'scroll'}}>
                          <Grid
                              data={this.state.history.data}
                              style={{ maxHeight: '100%', minWidth: '900px', fontSize: '1em' }}
                              filterable={true}
                              sortable={false}
                              pageable={true}
                              expandField="expanded"
                              filter={this.state.filter}
                              skip={this.state.history.from - 1}
                              take={30}
                              total={this.state.history.total}
                              onPageChange={(e) => {
                                  if(e.page.skip != (this.state.history.from - 1)) {
                                    this.setState({page: (e.page.skip / 30) + 1});
                                  }
                              }}
                              onFilterChange={(e) => {
                                  this.setState({
                                      filter: e.filter,
                                      page: 0
                                  });
                              }}
                              resizable
                          >
                              <GridColumn field="id" title="شناسه" filter="numeric" filterable={false} width={120} />
                              <GridColumn field="created_at" filterable={false} title="تاریخ" />
                              <GridColumn field="product_name" title="محصول" />
                              <GridColumn field="attribute_name" title="ترکیب" />
                              <GridColumn field="change_field" title="فیلد تغییر" />
                              <GridColumn field="change_value" filterable={false} title="مقدار جدید" />
                          </Grid>

                          <HistoryLoader
                                dataState={{
                                    filter: this.state.filter, 
                                    page: this.state.page
                                }}
                                onDataRecieved={this.dataRecieved}
                            />
                      </div>
                    </LocalizationProvider>
                  </div>
              }
          </div>
      );
  }
}

export default PricesHistory;