
import React from 'react';

export class Renderers {
    constructor(enterEdit, exitEdit, remoteEdit, editFieldName) {
        this.enterEdit = enterEdit;
        this.exitEdit = exitEdit;
        this.remoteEdit = remoteEdit;
        this.editFieldName = editFieldName;
    }

    cellRender = (tdElement, cellProps) => {
        const dataItem = cellProps.dataItem;
        const cellField = cellProps.field;
        const inEditField = dataItem[this.editFieldName];
        const additionalProps = cellField && cellField === inEditField ?
            {
                ref: (td) => {
                    const input = td && td.querySelector('input');
                    const activeElement = document.activeElement;

                    if (!input ||
                        !activeElement ||
                        input === activeElement ||
                        !activeElement.contains(input)) {
                    }

                }
            } : {
                onClick: () => { this.enterEdit(dataItem, cellField); },
            };
        return React.cloneElement(tdElement, { ...tdElement.props, ...additionalProps }, tdElement.props.children);
    };

    rowRender = (trElement) => {
        const trProps = {
            ...trElement.props,
            onMouseDown: () => {
                this.preventExit = true;
                clearTimeout(this.preventExitTimeout);
                this.preventExitTimeout = setTimeout(() => { this.preventExit = undefined; });
            },
            onBlur: () => {
                clearTimeout(this.blurTimeout);
                if (!this.preventExit) {
                    this.blurTimeout = setTimeout(() => { this.exitEdit(); });
                }
                this.remoteEdit();
            },
            onFocus: () => { clearTimeout(this.blurTimeout); },
            onKeyPress: (e) => {
                if(e.charCode === 13) {
                    this.remoteEdit();
                    this.exitEdit();
                }
            }
        };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    };
}
