import { all } from 'redux-saga/effects';
import priceListSagas from './priceList/saga';
import authSagas from './auth/saga';
import supplierSagas from './supplier/saga';

export default function* rootSaga(getState) {
    yield all([
        priceListSagas(),
        authSagas(),
        supplierSagas()
    ]);
}
