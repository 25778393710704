import React from 'react';
import Logo from '../assets/logo.jpg';
import { NavLink } from "react-router-dom";

class MainHeader extends React.Component {
    render() {
        return (
            <div className='header'>
                <div className="top-links">
                    <NavLink to="/"> لیست قیمت </NavLink>
                    <NavLink to="/price-list/history"> تاریخچه </NavLink>
                    <NavLink to="/price-list/supplier"> تامین کننده ها </NavLink>
                    {/*<NavLink to="/invoice"> فاکتور </NavLink>*/}
                </div>
                <div className="logo">
                    <img src={Logo} width={40} />
                    {/*<h3> ASAK CRM </h3>*/}
                </div>
                <div className="sign-out">
                    <NavLink to='/logout'>
                        خروج
                        <i className="lni lni-exit lni-lg"/>
                    </NavLink>
                </div>
            </div>
        );
    }
}

export default MainHeader;