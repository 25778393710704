
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {apiUrl} from "../../config/AppConfig";
import axios from 'axios';

export class HistoryLoader extends React.Component {
    baseUrl = apiUrl + '/api/apilog';
    lastSuccess = '';
    pending = '';

    serilizeFilter = (dataState) => {
        // console.log(filter, 'filters');
        let serializedFilter = '?';
        let filter = dataState.filter;
        let page = dataState.page;

        if(filter) {
            filter.filters.forEach(item => {
                serializedFilter += item.field + '=' + item.value + '&';
            });
        }

        if(page) {
            serializedFilter += 'page=' + page + '&';
        }

        return serializedFilter;
    };
    
    requestDataIfNeeded = () => {
        if (this.pending || this.serilizeFilter(this.props.dataState) === this.lastSuccess) {
            return;
        }
        this.pending = this.serilizeFilter(this.props.dataState);

        axios.get(this.baseUrl + this.pending, {
            auth: {
                username: localStorage.getItem('username'),
                password: localStorage.getItem('password')
            }
        }).then(res => {
            this.lastSuccess = this.pending;
                this.pending = '';
                if (this.serilizeFilter(this.props.dataState) === this.lastSuccess) {
                    this.props.onDataRecieved.call(undefined, 
                        res.data,
                    );
                } else {
                    this.requestDataIfNeeded();
                }
        })
    }

    render() {
        this.requestDataIfNeeded();
        return this.pending && <LoadingPanel />;
    }
}


class LoadingPanel extends React.Component {
    render() {
        const loadingPanel = (
            <div className="k-loading-mask">
                <span className="k-loading-text">Loading</span>
                <div className="k-loading-image"></div>
                <div className="k-loading-color"></div>
            </div>
        );

        const gridContent = document && document.querySelector('.k-grid-content');
        return gridContent ? ReactDOM.createPortal(loadingPanel, gridContent) : loadingPanel;
    }
}