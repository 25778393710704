import React, { Component } from "react";
import {connect} from "react-redux";
import {getCategoryList, getProductsGrid} from '../../data/priceList/actions';

class CategorySelect extends Component{
    constructor(props) {
        super(props);

        this.state = {
            groupData: [],
            selGroup: '',
            loading: false,
            mainCats:[],
            selMainCat: ''
        };
    }

    render() {
        const {
            allCategories
        } = this.props.priceApp;

        return (
                <div style={{ marginBottom: 20}}>
                    <ul className='mainCatDiv'>
                        {
                            allCategories.map((item, index) => item.children ?
                                <li key={index}>
                                    <div onClick={() => {
                                        this.setState({selMainCat: item});
                                    }} className={this.state.selMainCat === item ? 'selected' : ''}>
                                        {item.cat}
                                    </div>
                                </li> : ""
                            )
                        }
                    </ul>
                    {
                        allCategories.map((item, index) =>
                            <ul key={index} className={this.state.selMainCat === item ? 'tabPane selected' : 'tabPane'}>
                                { item.children ?
                                    item.children.map((item, index) => {
                                        if(item.children) {
                                            return item.children.map(child => child.children ?
                                                child.children.map(cchild => <li key={cchild.id_category}
                                                                                    className={this.state.selGroup === cchild ? 'subsubgroup selected' : 'subsubgroup'}
                                                                                    onClick={() => {
                                                                                        this.props.resetTotal();
                                                                                        this.props.resetSupplier();
                                                                                        this.setState({selGroup: cchild});
                                                                                        this.setState({gridData: this.props.getProductsGrid(cchild)});
                                                                                    }}>
                                                                                    {cchild.cat}
                                                                                </li>)
                                                :
                                                                                <li key={child.id_category}
                                                                                  className={this.state.selGroup === child ? 'subgroup selected' : 'subgroup'}
                                                                                  onClick={() => {
                                                                                      this.props.resetTotal();
                                                                                      this.props.resetSupplier();
                                                                                      this.setState({selGroup: child});
                                                                                      this.setState({gridData: this.props.getProductsGrid(child)});
                                                                                  }}>
                                                                                    {child.cat}
                                                                                </li>);
                                        } else {
                                            return <li key={index}
                                                       className={this.state.selGroup === item ? 'selected' : ''}
                                                       onClick={() => {
                                                            this.props.resetTotal();
                                                            this.props.resetSupplier();
                                                            this.setState({selGroup: item});
                                                            this.setState({gridData: this.props.getProductsGrid(item)});
                                                       }}>
                                                {item.cat}
                                            </li>
                                        }
                                    }) : ""
                                }
                            </ul>)
                    }
                </div>
        );
    }
}

const mapStateToProps = ({ priceApp }) => {
    return {
        priceApp
    };
};

export default connect(
    mapStateToProps,
    {
        getCategoryList,
        getProductsGrid
    }
)(CategorySelect);