import {
    PRODUCT_GET_LIST,
    PRODUCT_GET_LIST_SUCCESS,
    PRODUCT_GET_GRID,
    PRODUCT_SET_GRID,
    PRODUCT_SET_SOCKET_UPDATE,
    CATEGORY_GET_LIST,
    CATEGORY_GET_LIST_SUCCESS
} from '../actionTypes';

export const getProductsGrid = (group) => ({
    type: PRODUCT_GET_GRID,
    payload: group
});

export const setProductsGrid = (data) => ({
    type: PRODUCT_SET_GRID,
    payload: data
});

export const setProductsUpdate = (data) => ({
    type: PRODUCT_SET_SOCKET_UPDATE,
    payload: data
});

export const getProductsList = () => ({
    type: PRODUCT_GET_LIST
});

export const getProductsListSuccess = (items) => ({
    type: PRODUCT_GET_LIST_SUCCESS,
    payload: items
});

export const getCategoryList = () => ({
    type: CATEGORY_GET_LIST
});

export const getCategoryListSuccess = (items) => ({
    type: CATEGORY_GET_LIST_SUCCESS,
    payload: items
});