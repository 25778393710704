import React, { Component } from "react";
import {connect} from "react-redux";
import Echo from "laravel-echo";
import {setProductsUpdate, setProductsGrid} from '../data/priceList/actions';

class PusherListener extends Component{
    constructor(props) {
        super(props);

        this.state = {

        };

        window.Pusher = require('pusher-js');

        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: '84f8050d1364a412eb6c',
            cluster: 'eu'
        });

        window.Echo.channel('shopProduct')
            .listen("ShopProductUpdate", (e) => {
                // update all products
                const data = this.props.priceApp.allProducts.map(item =>
                    item.id_product === e.id_product && item.id_product_attribute === e.id_product_attribute ? {...item, [e.modifiedField]: e.modifiedValue} : item
                );

                this.props.setProductsUpdate(data);
                // update Grid products
                const gdata = this.props.priceApp.gridProducts.map(item =>
                    item.id_product === e.id_product && item.id_product_attribute === e.id_product_attribute ? {...item, [e.modifiedField]: e.modifiedValue} : item
                );

                this.props.setProductsGrid(gdata);
            });
    }

    render() {
        return ('');
    }
}

const mapStateToProps = ({ priceApp }) => {
    return {
        priceApp
    };
};

export default connect(
    mapStateToProps,
    {
        setProductsGrid,
        setProductsUpdate
    }
)(PusherListener);