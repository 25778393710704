import {
    SUPPLIER_LIST,
    SUPPLIER_LIST_SUCCESS,
} from '../actionTypes';

export const getSupplierList = () => ({
    type: SUPPLIER_LIST
});

export const getSupplierListSuccess = (items) => ({
    type: SUPPLIER_LIST_SUCCESS,
    payload: items
});