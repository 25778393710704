import React, { Component, Fragment } from "react";
import {connect} from "react-redux";
import { loadMessages, LocalizationProvider } from '@progress/kendo-react-intl';
import { Grid, GridColumn} from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { Card, CardHeader, CardTitle, CardBody } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import { Label } from '@progress/kendo-react-labels';
import { Input } from "@progress/kendo-react-inputs";
import '@progress/kendo-theme-default/dist/all.css';
import { apiUrl } from '../../config/AppConfig';
import messages from '../../lang/kendo-fa-IR';
import SupplierDetail from './supplierDetail';

import axios from "axios";

import {getSupplierList} from '../../data/supplier/actions';
import supplierApp from "../../data/supplier/reducer";

class Supplier extends Component {
    constructor(props) {
        super(props);

        this.state = {
            suppliers: [],
            gridDataState: {},

            title: '',
            tell: '',
            mobile: '',
            address: '',
            website: '',
            card: '',
            description: '',

            editId: 0,

            error: false,
            success: false,
            loading: false,
            editMode: false
        };

        const language = 'fa';
        loadMessages(messages, language);
    }

    componentDidMount() {
        this.props.getSupplierList()
    }

    addItem = () => {
        let self = this;
        this.setState({loading: true, success: false, error: false});
        const url =apiUrl+"/api/supplier";

        let item = {
            title: this.state.title,
            tell: this.state.tell,
            mobile: this.state.mobile,
            address: this.state.address,
            website: this.state.website,
            card: this.state.card,
            description: this.state.description
        };
        if(this.state.editMode) {
            axios.put(url + '/' + this.state.editId, item, {
                auth: {
                    username: 'apiuser',
                    password: 'apisecret'
                }
            }).then(res => {
                success();
            }).catch(err => {
                this.setState({loading: false, error: false});
                this.setState({error: err});
            });
        } else {
            axios.post(url, item, {
                auth: {
                    username: 'apiuser',
                    password: 'apisecret'
                }
            }).then(res => {
                success();
            }).catch(err => {
                this.setState({loading: false, error: false});
                this.setState({error: err});
            });
        }

        function success() {
            self.setState({
                loading: false,
                error: false,
                editMode: false,
                title: '',
                tell: '',
                mobile: '',
                address: '',
                website: '',
                card: '',
                description: '',
            });
            self.props.getSupplierList();
        }
    };

    deleteItem = (id) => {
        this.setState({loading: true, success: false, error: false});
        const url =apiUrl+"/api/supplier";

        axios.delete(url + '/' + id,{
            auth: {
                username: 'apiuser',
                password: 'apisecret'
            }
        }).then(res => {
            this.setState({loading: false, error: false});
            this.props.getSupplierList();
        }).catch(error => {
            this.setState({loading: false});
            this.setState({error: error.response.data.msg});
        });
    };

    render() {
        let { allSuppliers } = this.props.supplierApp;
        return (
        <div className="row k-rtl">
            { this.state.loading ? <div className="loading"></div> :
                <div style={{ padding: 20 }}>
                    <h3> تامین کننده ها
                    </h3>
                    { this.state.error ? <span className="errorMsg">{this.state.error}</span> : "" }
                    <Card className="k-rtl" style={{ boxShadow: '0 0 4px 0 rgba(0, 0, 0, .1)', marginTop: '15px', marginBottom: 10 }}>
                        <CardHeader className="k-hbox" style={{background: 'transparent'}}>
                            <CardTitle style={{marginBottom: '4px'}}> افزودن </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <div className="vertical-form">
                                <div className="form-group">
                                    <Label> عنوان: </Label>
                                    <div style={{ marginRight: 10 }}>
                                        <Input type="text" className="form-control" onChange={(e) => {
                                            this.setState({title: e.target.value})
                                        }}
                                               value={this.state.title}
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <Label> تلفن: </Label>
                                    <div style={{ marginRight: 10 }}>
                                        <Input type="text" className="form-control" onChange={(e) => {
                                            this.setState({tell: e.target.value})
                                        }}
                                               value={this.state.tell}
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <Label> موبایل: </Label>
                                    <div style={{ marginRight: 10 }}>
                                        <Input type="text" className="form-control" onChange={(e) => {
                                            this.setState({mobile: e.target.value})
                                        }}
                                               value={this.state.mobile}
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <Label> آدرس: </Label>
                                    <div style={{ marginRight: 10 }}>
                                        <Input type="text" className="form-control" onChange={(e) => {
                                            this.setState({address: e.target.value})
                                        }}
                                               value={this.state.address}
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <Label> وب سایت: </Label>
                                    <div style={{ marginRight: 10 }}>
                                        <Input type="text" className="form-control" onChange={(e) => {
                                            this.setState({website: e.target.value})
                                        }}
                                               value={this.state.website}
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <Label> شماره کارت: </Label>
                                    <div style={{ marginRight: 10 }}>
                                        <Input type="text" className="form-control" onChange={(e) => {
                                            this.setState({card: e.target.value})
                                        }}
                                               value={this.state.card}
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <Label> توضیحات: </Label>
                                    <div style={{ marginRight: 10 }}>
                                        <Input rows={2} className="form-control" onChange={(e) => {
                                            this.setState({description: e.target.value})
                                        }}
                                               value={this.state.description}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Button icon="save" style={{ marginRight: 10 }} onClick={ this.addItem }>{ this.state.editMode ? 'ویرایش' : 'افزودن' }
                            </Button>
                        </CardBody>
                    </Card>

                    <LocalizationProvider language="fa">
                        <div dir="rtl" className="k-rtl my-3" style={{ height: '75vh', overflow: 'scroll'}}>
                            <Grid
                                data={process(allSuppliers, this.state.gridDataState)}
                                style={{ maxHeight: '100%', minWidth: '900px', fontSize: '1.2em' }}
                                filterable={true}
                                sortable={false}
                                pageable={false}
                                expandField="expanded"
                                detail={SupplierDetail}
                                {...this.state.gridDataState}
                                onDataStateChange={(e) => {
                                    this.setState({ gridDataState: e.data })
                                }}
                                onExpandChange={
                                    event => {
                                        event.dataItem.expanded = !event.dataItem.expanded;
                                        this.forceUpdate();
                                    }
                                }
                                resizable
                            >
                                <GridColumn field="id" title="شناسه" filter="numeric" width={120} />
                                <GridColumn field="supplier_title" title="عنوان" />
                                <GridColumn field="tell" title="تلفن" />
                                <GridColumn field="mobile" title="موبایل" />
                                <GridColumn field="card" title="شماره کارت" />
                                <GridColumn field="website" title="وب سایت" />
                                <GridColumn title="عملیات" filterable={false} width={200} cell={(props) => { return (
                                    <td className="k-d-flex k-align-items-center">
                                        <Button className="btn btn-info btn-sm m-1">
                                                <span className="text-light" onClick={() => {
                                                    this.setState({
                                                        editMode: true,
                                                        editId: props.dataItem.id,
                                                        title: props.dataItem.supplier_title,
                                                        description: props.dataItem.description == null ? '' : props.dataItem.description,
                                                        address: props.dataItem.address == null ? '' : props.dataItem.address,
                                                        tell: props.dataItem.tell == null ? '' : props.dataItem.tell,
                                                        mobile: props.dataItem.mobile == null ? '' : props.dataItem.mobile,
                                                        card: props.dataItem.card == null ? '' : props.dataItem.card,
                                                        website: props.dataItem.website == null ? '' : props.dataItem.website,
                                                    });
                                                }}>
                                                    ویرایش
                                                </span>
                                        </Button>
                                        <Button style={{ marginRight: 10 }}
                                                onClick={() => this.deleteItem(props.dataItem.id)}
                                        >
                                                <span className="text-light">
                                                    حذف
                                                </span>
                                        </Button>
                                    </td>
                                ) }}/>
                            </Grid>
                        </div>
                    </LocalizationProvider>
                </div> }
        </div>);
    }
}

const mapStateToProps = ({ supplierApp }) => {
    return {
        supplierApp
    };
};

export default connect(
    mapStateToProps,
    {
        getSupplierList,
    }
)(Supplier);